<template>
    <v-container fluid tag="section">

        <div class="text-end">
            <v-btn outlined class="font-weight-bold me-2" :to="`/item-categories`">{{ $t('Cancel') }}</v-btn>
            <v-btn color="purple" class="font-weight-bold me-0" @click="onSubmit" :loading="loading" :disabled="loading">{{ $t('Save') }}</v-btn>
        </div>

        <v-row>
            <v-col cols="12">
                <v-card class="rounded-lg mt-2">
                    <v-card-text class="px-0 py-0">
                        <v-form>
                            <v-container>
                                <v-text-field v-model="item.name" :label="$t('Name')"></v-text-field>
                                <v-textarea v-model="item.desc" :label="$t('Description')"></v-textarea>
                                <v-select v-model="item.company_id" :label="$t('Company')" :items="companies"
                                item-text="name" item-value="id"></v-select>
                                <v-text-field v-model="item.sort" :label="$t('Sort')"></v-text-field>
                                <v-img v-if="item.urls?.thumb" :src="item.urls.thumb" max-height="64" max-width="64"></v-img>
                                <v-file-input v-model="item.thumb" :label="$t('Thumb')" truncate-length="25" prepend-icon="mdi-image"></v-file-input>
                                <v-checkbox v-model="item.is_public" :label="$t('Public')"></v-checkbox>
                                <v-checkbox v-model="item.is_active" :label="$t('Active')"></v-checkbox>
                            </v-container>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import itemCategoryService from '@/services/itemCategoryService'
import companyService from '@/services/companyService'

export default {
    data() {
        return {
            loading: false,
            item: {
                company_id: null,
                name: null,
                desc: null,
                sort: 0,
                is_public: false,
                is_active: true,
            },
            companies: []
        }
    },
    async created() {
        if (this.$route.params.id) {
            await this.getItemCategory(this.$route.params.id)
        }
        this.getCompanies()
    },
    methods: {
        async getItemCategory(id) {
            try {
                const result = await itemCategoryService.get(id)
                this.item = result.data
            } catch (error) {
                this.$dialog.message.error(error.message)
                this.$router.push('/item-categories')
            }
        },
        async getCompanies() {
            try {
                const result = await companyService.getAll()
                this.companies = result.data
            } catch (error) {
                this.$dialog.message.error(error.message)
            }
        },
        async onSubmit() {
            this.loading = true
            try {
                if (this.$route.params.id) {
                    await itemCategoryService.edit(this.$route.params.id, this.item)
                } else {
                    await itemCategoryService.add(this.item)
                }
                this.$dialog.message.success(this.$t('Success'))
                this.$router.push('/item-categories')
            } catch (error) {
                this.$dialog.message.error(error.message)
            }
            this.loading = false
        }
    },
}
</script>